

export const initMenu = () => {
  const header = document.querySelector('.page-header');
  const menuBtn = document.querySelector('.page-header__menu-btn');

  if (menuBtn && header) {
    header.classList.add('page-header--closed');
    menuBtn.addEventListener('click', () => {
      header.classList.toggle('page-header--closed');
      // Фиксирует прокрутку страницы при открытии бургер-меню
      // document.body.classList.toggle('body-fixed');
    });
  }

  const langSelector = document.querySelector('.page-header__lang');
  const currentLang = document.querySelector('.page-header__lang-current');

  if (currentLang && langSelector) {
    langSelector.classList.add('page-header__lang--closed');
    currentLang.addEventListener('click', () => {
      langSelector.classList.toggle('page-header__lang--closed');
    });
  }

};
