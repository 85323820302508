function displayGoTopBtn() {
  const btn = document.querySelector('[data-type="go-top-btn"]');

  if (!btn) {
    return;
  }

  window.addEventListener('scroll', () => {
    if (window.scrollY > 500) {
      btn.classList.add('go-top-btn--show');
    } else {
      btn.classList.remove('go-top-btn--show');
    }
  });
}

function handleGoTopBtn() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

function handleBackBtn() {
  history.back();
}

export function handleBtns() {
  displayGoTopBtn();

  document.addEventListener('click', (event) => {
    const target = event.target;
    const btnType = target ? target.dataset.type : '';

    switch (btnType) {
      case 'back-btn':
        handleBackBtn();
        break;
      case 'go-top-btn':
        handleGoTopBtn(event);
        break;
      default:
        return;
    }
  });
}
