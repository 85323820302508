

export const initQuestionsSection = () => {
  const questionItems = document.querySelectorAll('.questions-section__item');
  const showMoreBtn = document.querySelector('.questions-section__show-more');

  if (showMoreBtn) {
    showMoreBtn.addEventListener('click', (e) => {
      questionItems.forEach((el) => {
        el.classList.add('questions-section__item--show');
      });
      showMoreBtn.style.display = 'none';
    });
  }

  if (questionItems.length) {
    questionItems.forEach((el) => {
      el.classList.add('questions-section__item--closed');
      const content = el.querySelector('.questions-section__item-content');

      el.addEventListener('click', () => {
        el.classList.toggle('questions-section__item--closed');

        if (el.classList.contains('questions-section__item--closed')) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + 'px';
        }
      });
    });
  }
};
